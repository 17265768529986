export function useTrack() {
  const track = (eventName: string, data: Record<string, string | number | boolean> = {}) => {
    const { company } = useStoreCompany()

    if (!company) return

    useNuxtApp().$mixpanel.track(eventName, {
      ...data,
      company_id: company.id,
      company_name: company.name,
      is_shopify: useEmbedded().isEmbedded.value,
      is_connected: useStoreAuth().isAuthenticated
    })
  }

  return { track }
}
