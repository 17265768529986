export default defineNuxtRouteMiddleware((to) => {
  const allowed = ['play-tiktok-oauth', 'play-shopify-account', 'play-shopify-referral', 'play-unsubscribe']

  if (allowed.includes(to.name as string)) return

  const { companyId } = to.params

  if (!companyId || typeof companyId === 'undefined') {
    const { company } = useStoreCompany()
    if (company) {
      const home = getUrlPrefixFromCompany(company)
      return navigateTo(home)
    }

    return navigateTo('https://www.loyoly.io', { external: true })
  }
})
