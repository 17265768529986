import { useQuery } from '@tanstack/vue-query'
import type { ClaimedReward } from '~/types/reward'
import type { Store, StorePage, StoreLocales } from '~/types/store'

export const useStoreStore = defineStore('store', () => {
  const embeddedStoresMap = ref(new Map<number, boolean>())
  const embeddedCustomerIdMap = ref(new Map<number, number>())

  const scrollPosition = ref(0)
  const viewportHeight = ref(0)
  const appHeight = ref(0)
  const offset = ref(0)
  const modalHeight = ref(0)
  const modalTop = ref(0)

  const langCode = ref<string | null>(null)

  const currencyCodeExtension = ref<string | null>(null)
  const currencyRate = ref<number>(1.0)

  const store = ref<Store | null>(null)

  const currencyCode = computed(() => {
    // currencyCodeExtension.value from shopify international extension (browsing user locale)
    // store.value?.currency_code is fallback (store locale)
    return currencyCodeExtension.value ?? store.value?.currency_code ?? 'EUR'
  })

  const fetchStore = (companyId: number) => {
    useQuery({
      queryKey: ['fetchStore', companyId],
      queryFn: async () => {
        const [storeFetched] = (await useNuxtApp().$api(`/api/creator/stores/?company_id=${companyId}`)) as Store[]
        store.value = storeFetched
        return storeFetched ?? null
      }
    })
  }

  const fetchStoreFromName = async (storeName: string) => {
    const [storeFetched] = (await useNuxtApp().$api(`/api/creator/stores/`, {
      params: { name: storeName }
    })) as Store[]
    store.value = storeFetched
    return storeFetched ?? null
  }

  const langList = ref<StoreLocales[] | null>([])

  const fetchLocales = async (companyId: number) => {
    const locales: StoreLocales[] = await useNuxtApp().$api(`/api/creator/locales/?company_id=${companyId}`)
    langList.value = locales
  }

  const isCurrentLangActive = computed((): boolean => {
    if (!langCode.value || !langList.value) return false

    return !!langList.value.find((lang) => lang.locale === langCode.value && lang.active)
  })

  const primaryLocale = computed(() => {
    if (!langList.value) return null

    const lang = langList.value.find((lang) => lang.primary)
    return lang ? lang.locale : null
  })

  const getPages = (companyId: number | undefined) => {
    return useQuery({
      queryKey: ['listPages', companyId],
      queryFn: async (): Promise<StorePage[]> => {
        return await useNuxtApp().$api(`/api/creator/pages/?company_id=${companyId}`)
      },
      enabled: !!companyId
    })
  }

  const addRewardToCart = (claimedReward: ClaimedReward) => {
    if (
      claimedReward.reward.promo_type === 'PRO' &&
      (claimedReward.reward.variant_ids.length === 1 ||
        claimedReward.promocode.reward?.variant_external_ids.length === 1)
    ) {
      window.parent.postMessage(
        {
          variantId:
            claimedReward.promocode.reward?.variant_external_ids.length === 1
              ? claimedReward.promocode.reward?.variant_external_ids[0]
              : claimedReward.reward.variant_ids[0],
          addToCard: true,
          isWeWiink: true,
          promocode: claimedReward.promocode.name
        },
        '*'
      )
      return
    }
    window.parent.location = `${store.value!.url}/discount/${claimedReward.promocode.name}`
  }

  const unsubscribeEmail = async (email: string, companyId: number) => {
    // TODO : change route when API route ready
    const res = await useNuxtApp().$api(`/api/creator/stores/${companyId}/unsubscribe/`, {
      method: 'POST',
      body: {
        email
      }
    })
    return res
  }

  const subscribeEmail = async (email: string, companyId: number) => {
    // TODO : change route when API route ready
    const res = await useNuxtApp().$api(`/api/creator/stores/${companyId}/subscribe/`, {
      method: 'POST',
      body: {
        email
      }
    })
    return res
  }

  const setIsEmbeddedStore = (companyId: number, isEmbedded: boolean) =>
    embeddedStoresMap.value.set(companyId, isEmbedded)

  const setHasCustomerId = (companyId: number, customerId: number) =>
    embeddedCustomerIdMap.value.set(companyId, customerId)

  const embeddedViewportHeight = computed(() => {
    return viewportHeight.value - offset.value
  })

  return {
    store,
    embeddedStoresMap,
    setIsEmbeddedStore,
    fetchStore,
    fetchStoreFromName,
    getPages,
    embeddedCustomerIdMap,
    setHasCustomerId,
    addRewardToCart,
    scrollPosition,
    viewportHeight,
    appHeight,
    offset,
    embeddedViewportHeight,
    modalHeight,
    modalTop,
    langCode,
    currencyCodeExtension,
    currencyRate,
    currencyCode,
    isCurrentLangActive,
    fetchLocales,
    primaryLocale,
    unsubscribeEmail,
    subscribeEmail
  }
})
