export const useStoreApp = defineStore('app', () => {
  const isModalFullPage = ref(false)
  const isAppEmbedded = ref(false)
  const modalsOpenedCount = ref(0)
  const appWidth = ref(0)

  const domModalsCount = ref(0)

  const isMobile = computed(() => appWidth.value < 768)

  const companyId = ref(0)

  return {
    companyId,
    isModalFullPage,
    isAppEmbedded,
    modalsOpenedCount,
    isMobile,
    appWidth,
    domModalsCount
  }
})
