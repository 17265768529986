import validate from "/opt/build/repo/node_modules/nuxt/dist/pages/runtime/validate.js";
import redirect_45global from "/opt/build/repo/middleware/redirect.global.ts";
import manifest_45route_45rule from "/opt/build/repo/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  redirect_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  company: () => import("/opt/build/repo/middleware/company.ts"),
  shopify: () => import("/opt/build/repo/middleware/shopify.ts")
}